import axios from 'axios';
import appConstant from './constant.json';

const params = {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*'
}

export const getFactuly = () =>{
    return axios.get(`${appConstant.API_URL}/v1/c/faculty`, params)
}

export const getMenu = () =>{
    return axios.get(`${appConstant.API_URL}/v1/c/faculty`, params)
}

export const getHome = () =>{
    return axios.get(`${appConstant.API_URL}/v1/c/home`, params)
}