import React, {useState} from 'react';
import axios from "axios";
import appConstant from './constant/constant.json';
import { HashLink } from 'react-router-hash-link';
import { Link } from 'react-router-dom';

function Footer() {
    const [formMessage, setFormMessage] = useState('');
    const [inputValues, setInputValues] = useState({
        fname : "",
        email : "",
        number : "",
        message : ""
    });
    const handelChange = (e) => {
        const {name, value} = e.target;
        setInputValues({...inputValues, [name]: value});
    }
    const [validation, setValidation] = useState({
        fname : "",
        email : "",
        number : ""
    });
    const checkValidation = () => {
        let errors = JSON.parse(JSON.stringify(validation));
        //fullname validation
        const alphabetCond = /^[a-zA-Z ]*$/;
        if (!inputValues.fname) {
        errors.fname = "Name is required";
        } else if (!inputValues.fname.match(alphabetCond)) {
        errors.fname = "Please enter valid Name";
        } else {
        errors.fname = "";
        }
        //number validation
        const numberCond = /^[0-9\b]+$/;
        if (!inputValues.number) {
          errors.number = "Number is required";
        } else if (!inputValues.number.match(numberCond)) {
          errors.number = "Please enter valid number";
        } else if (inputValues.number.length < 9) {
          errors.number = "Please enter valid phone number.";
        } else {
          errors.number = "";
        }
        // email validation
        const emailCond = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
        if (!inputValues.email) {
          errors.email = "Email is required";
        } else if (!inputValues.email.match(emailCond)) {
          errors.email = "Please enter valid email address";
        } else {
          errors.email = "";
        }
        setValidation(errors);
        if(errors.fname === '' && errors.number === '' && errors.email === ''){
          return true;
        }else{
          return false;
        }
    };
    const handleSubmit = (e) => {
        e.preventDefault();
        const isSuccess = checkValidation();
        if(isSuccess) {
            const params = {
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*"
            };
            const data = new FormData();
            data.append('full_name', inputValues.fname);
            data.append('phone_number', inputValues.number);
            data.append('email', inputValues.email);
            data.append('message', inputValues.message);
            axios.post(`${appConstant.API_URL}/contact`, data, params)
                .then(res => {
                    if (res?.data?.status === 200) {
                        setFormMessage(res?.data?.message);
                        const inputData = {
                            fname : "",
                            email : "",
                            number : "",
                            service : ""
                        }
                        setInputValues(inputData);
                    }else{
                        setFormMessage(res?.data?.message);
                    }
                })
                .catch(error => {
                });
        }
    }
    return (
        <>
        <section className='footer sections'>
            <div className='container'>
                    <div className='row'>
                        <div className='col-xl-3 col-sm-6 mb-4 mb-xl-0'>
                            <a className="footer-logo" href="/">
                                <img className='logo' alt='logo' src='/assets/images/footer-logo.png' />
                            </a>
                            <p className='paragraph'>IIMS will be proved best choice for students who want to pursue a career in diverse field of Media.</p>
                        </div>
                        <div className='col-xl-3 col-sm-6 mb-4 mb-xl-0'>
                            <h6 className='sub-heading'>Courses</h6>
                            <Link to='/course-detail' className='footer-link'>PG Diploma in Journalism</Link>
                            <Link to='/course-detail' className='footer-link'>PG Diploma in Graphics and Editing</Link>
                            <Link to='/course-detail' className='footer-link'>Diploma in Journalism</Link>
                            <Link to='/course-detail' className='footer-link'>Diploma in Graphics and Editing</Link>
                            <Link to='/course-detail' className='footer-link'>Diploma in Media Research</Link>
                            <Link to='/course-detail' className='footer-link'>Certificate courses</Link>
                        </div>
                        <div className='col-xl-3 col-sm-6 mb-4 mb-sm-0'>
                            <h6 className='sub-heading'>Quick Links</h6>
                            <HashLink className='footer-link' smooth  to="/#faculty">Faculty</HashLink>
                            <Link className='footer-link' smooth  to="/about">About Us</Link>
                            <Link className='footer-link' smooth  to="/contact">Contact Us</Link>
                        </div>
                        <div className='col-xl-3 col-sm-6'>
                            <h6 className='sub-heading'>Help</h6>
                            <h6  className='footer-link'><a href={`tel:${appConstant.PHONE_URL}`} rel="noreferrer"><i className="fa-solid fa-phone"></i> &nbsp; {`${appConstant.PHONE_URL}`}</a></h6>
                            <h6 className='footer-link'><a href={`mailto:${appConstant.EMAIL_URL}`} rel="noreferrer"><i className="fa-solid fa-envelope"></i> &nbsp; {`${appConstant.EMAIL_URL}`}</a></h6>
                            <h6 className='footer-link'><i className="fa-solid fa-location-dot"></i> &nbsp; {`${appConstant.ADDRESS_URL}`}</h6>
                            <div className='d-flex justify-content-center w-100'>
                                <a className="contact-icons" href={`${appConstant.FACEBOOK_URL}`} target="_blank" rel="noreferrer"><i className="fa-brands fa-facebook"></i></a>
                                <a className="contact-icons" href={`${appConstant.TWITTER_URL}`} target="_blank" rel="noreferrer"><i className="fa-brands fa-twitter"></i></a>
                                <a className="contact-icons" href={`${appConstant.LINKEDIN_URL}`} target="_blank" rel="noreferrer"><i className="fa-brands fa-linkedin"></i></a>
                                <a className="contact-icons" href={`${appConstant.INSTAGRAM_URL}`} target="_blank" rel="noreferrer"><i className="fa-brands fa-instagram"></i></a>
                            </div>
                        </div>
                    </div>
            </div>            
        </section>
        {/* enquiry */}
        <button type="button" className="enquiry-btn" data-bs-toggle="modal" data-bs-target="#enquiry">
            Inquire Us
        </button>
        <div className="modal" id="enquiry">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header">
                        <h4 className="modal-title text-black">Enquiry Form</h4>
                        <button type="button" className="btn-close" data-bs-dismiss="modal"></button>
                    </div>
                    <div className="modal-body">
                        {formMessage && <p className='formSuccess'>{formMessage}</p>}
                        <form action='/' onSubmit={handleSubmit}>
                            <div className='input-box mb-3'>
                                <label className='contact-label'>Name</label>
                                <input className='contact-input' name='fname' value={inputValues.fname} onChange={handelChange} placeholder='Enter your full name' />
                                {validation.fname && <p className='formErrors'>{validation.fname}</p>}
                            </div>
                            <div className='input-box mb-3'>
                                <label className='contact-label'>Email</label>
                                <input className='contact-input' name='email' value={inputValues.email} onChange={handelChange} placeholder='Enter your email id'/>
                                {validation.email && <p className='formErrors'>{validation.email}</p>}
                            </div>
                            <div className='input-box mb-3'>
                                <label className='contact-label'>Phone</label>
                                <input className='contact-input' name='number' value={inputValues.number} onChange={handelChange} placeholder='Enter your phone number'/>
                                {validation.number && <p className='formErrors'>{validation.number}</p>}
                            </div>
                            <div className='input-box mb-4'>
                                <label className='contact-label'>Message</label>
                                <textarea className='contact-input' rows='3' name='message' value={inputValues.message} onChange={handelChange} placeholder='Enter your message' ></textarea>
                            </div>
                            <button className='button orange-button button-2' type='submit' >Submit</button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        </>
    );
}
export default Footer;