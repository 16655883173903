import React from 'react';
import ReactDOM from 'react-dom/client';
import Home from './Home';
import About from './About';
import WhyIIMS from './WhyIIMS';
import CourseDetail from './CourseDetail';
import Contact from './Contact';

import reportWebVitals from './reportWebVitals';
import { BrowserRouter,Routes, Route } from 'react-router-dom';
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <BrowserRouter>
      <Routes>
        <Route exact path="/" element={<Home />}></Route>
        <Route exact path="/about" element={<About />}></Route>
        <Route exact path="/why-iims" element={<WhyIIMS />}></Route>
        <Route exact path="/course-detail" element={<CourseDetail />}></Route>
        <Route exact path="/contact" element={<Contact />}></Route>
        <Route path="*" element={<Home />}></Route>
      </Routes>
    </BrowserRouter>
);

reportWebVitals();