import React from 'react';
import Header from './shared/Header';
import Footer from './shared/Footer';
import FloatUpComponent from './shared/FloatUpComponent';
import Banner from './shared/Banner';

function WhyIIMS() {
    return (
        <>
            <Header id="2" />
            <Banner />
            <FloatUpComponent>
                <section className='sections'>
                    <div className='container'> 
                        <h3 className='heading drop-in'>Why IIMS
                            <br/><span className='line-text'><span className='line-cap'><i className="fa-solid fa-graduation-cap"></i></span></span>
                        </h3>
                        <p className='paragraph drop-in-2'>
                        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                        <p className='paragraph drop-in-2'>
                        It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
                        </p>                   
                        <div className='row m-0 p-0 align-items-center mt-5 mb-5'>
                            <div className='col-lg-6 mb-4 mb-lg-0 p-0'>                                    
                                <h6 className='sub-heading drop-in-2'>Audio-Visual Studio
                                </h6>
                                <p className='paragraph drop-in-2'>
                                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                                <p className='paragraph drop-in-2'>
                                It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
                                </p> 
                            </div>
                            <div className='col-lg-6 p-0'>
                                <img src="/assets/images/iims-1.jpg" className="aboutPage-image-2 drop-in-2" alt="about" />
                            </div>
                        </div>
                        <hr/>
                        <div className='row m-0 p-0 align-items-center mt-5'>
                            <div className='col-lg-6 p-0 order-2 order-lg-1'>
                                <img src="/assets/images/iims-2.jpg" className="aboutPage-image-2 drop-in-2" alt="about" />
                            </div>
                            <div className='col-lg-6 mb-4 mb-lg-0 p-0 order-1 order-lg-2'>                                    
                                <h6 className='sub-heading drop-in-2'>Media Industry Environment

                                </h6>
                                <p className='paragraph drop-in-2'>
                                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                                <p className='paragraph drop-in-2'>
                                It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
                                </p> 
                            </div>                           
                        </div>
                    </div>
                </section>
            </FloatUpComponent>
            <Footer /> 
        </>
    );
}
export default WhyIIMS;