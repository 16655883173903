import React from 'react';
import Header from './shared/Header';
import Footer from './shared/Footer';
import FloatUpComponent from './shared/FloatUpComponent';

function About() {
    return (
        <>
            <Header id="2" />
            <FloatUpComponent>
                <section className='sections'>
                    <div className='container'> 
                        <h3 className='heading drop-in'>About Us
                            <br/><span className='line-text'><span className='line-cap'><i className="fa-solid fa-graduation-cap"></i></span></span>
                        </h3>
                        <p className='paragraph drop-in-2'>
                        IIMS is set up in 2024 to cater to the growing demand of students interested in pursuing a career in Broadcast Journalism, Media Management, Social Media, Graphics & Animation, PR/Corporate Communication, Event Management, Bollywood and also acquiring necessary skills in News Anchoring, Cinematography and Video Editing.</p>
                        <p className='paragraph drop-in-2'>
                        IIMS is located in the heart of NIT Faridabad near to Escort-Mujesar Metro station. Overall, IIMS will be proved best choice for students who want to pursue a career in diverse field of Media. IIMS offers a comprehensive learning experience that prepare students for success in the Media & Graphic industry.
                        </p>                   
                        <div className='row m-0 p-0 align-items-center mt-5'>
                            <div className='col-lg-6 mb-4 mb-lg-0 p-0'>                                    
                                <h6 className='sub-heading drop-in-2'>World famous Media Institute Collaboration</h6>
                                <p className='paragraph drop-in-2'>The Institute is putting continuous effort to collaborate with following World Famous Media Institute & Universities to extend their facilities to its students:</p>
                                <ol className='about-list drop-in-2'>
                                    <li className='paragraph'>Film & Television Institute of India (FTII)</li>
                                    <li className='paragraph'>Satyajit Ray Institute of Film & Television</li>
                                    <li className='paragraph'>Centre for Media Studies (CMS)</li>
                                    <li className='paragraph'>Indian Institute of Mass Communication (IIMC)</li>
                                    <li className='paragraph'>Universities from South Korea</li>
                                    <li className='paragraph'>Universities from Iran</li>
                                </ol>
                            </div>
                            <div className='col-lg-6 p-0'>
                                <img src="/assets/images/about-page.png" className="aboutPage-image" alt="about" />
                            </div>
                        </div>
                    </div>
                </section>
            </FloatUpComponent>
            <Footer /> 
        </>
    );
}
export default About;