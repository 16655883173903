import React, { useEffect, useState } from 'react';
import Header from './shared/Header';
import Footer from './shared/Footer';
import FloatUpComponent from './shared/FloatUpComponent';
import appConstant from './shared/constant/constant.json';
import { Link } from 'react-router-dom';
import { getFactuly, getHome } from './shared/constant/services';

function Home() {
    const [faculty, setFaculty] = useState([]);
    const [homeData, setHomeData] = useState([]);
    useEffect(() =>{
        getFactuly().then((res) => {
            if(res.status === 200){
                setFaculty(res.data.data)
            }
        }).catch((error) =>{
        })

        getHome().then((res) => {
            if(res.status === 200){
                setHomeData(res.data.data[0])
            }
        }).catch((error) =>{
        })
    }, [])
    return (
        <>  {/* header */}
            <Header id="1" />
            {/* banner */}
            <section id="banner" className="carousel slide" data-bs-ride="carousel" data-bs-interval="10000">
                <div className="carousel-inner">
                    <div className="carousel-item active">
                        <div className="landing-banner">
                            <img className="banner-image" src="/assets/images/banner-1.jpg" alt="banner"/>
                            <div className="landing-overlay">
                                <h6 className="banner-heading drop-in">Leave an everlasting first impression</h6>
                                <p className='banner-content drop-in-2'>Catch your client's attention from the first visit itself with our  finest range of reception <br/>counters, unique lift flooring designs, state-of-the-art bathroom vanities, <br/> beautiful wall claddings, and much more.</p>
                            </div>
                        </div>
                    </div>
                    <div className="carousel-item">
                        <div className="landing-banner">
                            <img className="banner-image" src="/assets/images/banner-2.jpg" alt="banner"/>
                            <div className="landing-overlay">
                                <h6 className="banner-heading drop-in">Leave your guests Awestruck!</h6>
                                <p className="banner-content drop-in-2">
                                    Elevate the guests' visit experience with our exquisite range <br/>
                                    of furniture &amp; Table Tops, a wide variety of sinks, <br/>
                                    beautiful wall claddings, and much more.
                                </p>
                            </div>
                        </div> 
                    </div>
                </div>
                <button className="carousel-control-prev" type="button" data-bs-target="#banner" data-bs-slide="prev">
                    <span className="carousel-control-prev-icon"></span>
                </button>
                <button className="carousel-control-next" type="button" data-bs-target="#banner" data-bs-slide="next">
                    <span className="carousel-control-next-icon"></span>
                </button>
            </section>
            {/* features */}
            <FloatUpComponent>
                <section className='features'>
                    <div className='container'>
                        <div className='row text-white'>
                            <div className='col-sm-4 bg-green'>
                                <div className='feature-columns'>
                                    <h4 className='feature-heading'><a href="/" target='_blank'>Introduction</a></h4>
                                    <p className='feature-content'>{homeData?.text1}</p>
                                </div>                                
                            </div>
                            <div className='col-sm-4 bg-orange'>
                                <div className='feature-columns'>
                                    <h4 className='feature-heading'><a href="/" target='_blank'>Departments</a></h4>
                                    <p className='feature-content'>{homeData?.text2}</p>
                                </div>
                            </div>
                            <div className='col-sm-4 bg-sky-blue'>
                                <div className='feature-columns'>
                                    <h4 className='feature-heading'><a href="/" target='_blank'>Institute</a></h4>
                                    <p className='feature-content'>{homeData?.text3}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </FloatUpComponent>
            {/* about us */}
            <FloatUpComponent>
                <section className='sections'>
                    <div className='container'>                    
                        <div className='row m-0 p-0 align-items-center'>
                            <div className='col-xxl-6 mb-4 mb-xxl-0'>
                                    <h3 className='heading drop-in'>About Us
                                        <br/><span className='line-text'><span className='line-cap'><i className="fa-solid fa-graduation-cap"></i></span></span>
                                    </h3>
                                <div className='paragraph drop-in-2' dangerouslySetInnerHTML={{ __html: homeData?.about_us }}></div>
                                <div className='text-center'>
                                    <Link to='/about' className="button orange-button button-2">Read More <i className="fa-solid fa-arrow-right"></i></Link>
                                </div>
                            </div>
                            <div className='col-xxl-6'>
                                <img src="/assets/images/about-page.png" className="aboutPage-image" alt="about" />
                            </div>
                        </div>
                    </div>
                </section>
            </FloatUpComponent>
            {/* career */}
            <FloatUpComponent >
                <section className='sections bg-alternate' id='career'>
                    <div className='container'>                    
                        <div className='row m-0 p-0 align-items-center'>
                            <div className='col-lg-12'>
                                <h3 className='heading drop-in'>Career Options
                                    <br/><span className='line-text'><span className='line-cap'><i className="fa-solid fa-graduation-cap"></i></span></span>
                                </h3>
                                <p className='paragraph drop-in-2'>{homeData?.career_content}</p>
                                <div className='feature-card-box row m-0 p-0 mt-5'>
                                    <div className='feature-card mb-4 col-xxl-3 col-lg-4 col-sm-6 col-12'>
                                        <div className='card-box card-1'>
                                            <div className="icon"><img src='/assets/images/recruitment.png' alt='icons' /></div>
                                            <h6 className='mini-title'>Reporter</h6>
                                        </div>
                                    </div>
                                    <div className='feature-card mb-4 col-xxl-3 col-lg-4 col-sm-6 col-12'>
                                        <div className='card-box card-2'>
                                            <div className="icon"><img src='/assets/images/library.png' alt='icons' /></div>
                                            <h6 className='mini-title'>Cinematographer</h6>
                                        </div>
                                    </div>
                                    <div className='feature-card mb-4 col-xxl-3 col-lg-4 col-sm-6 col-12'>
                                        <div className='card-box card-3'>
                                            <div className="icon"><img src='/assets/images/book.png' alt='icons' /></div>
                                            <h6 className='mini-title'>Photographer</h6>
                                        </div>
                                    </div>
                                    <div className='feature-card mb-4 col-xxl-3 col-lg-4 col-sm-6 col-12'>
                                        <div className='card-box card-4'>
                                            <div className="icon"><img src='/assets/images/recruitment.png' alt='icons' /></div>
                                            <h6 className='mini-title'>Anchor</h6>
                                        </div>
                                    </div>
                                    <div className='feature-card mb-4 col-xxl-3 col-lg-4 col-sm-6 col-12'>
                                        <div className='card-box card-5'>
                                            <div className="icon"><img src='/assets/images/library.png' alt='icons' /></div>
                                            <h6 className='mini-title'>Actor</h6>
                                        </div>
                                    </div>
                                    <div className='feature-card mb-4 col-xxl-3 col-lg-4 col-sm-6 col-12'>
                                        <div className='card-box card-6'>
                                            <div className="icon"><img src='/assets/images/book.png' alt='icons' /></div>
                                            <h6 className='mini-title'>Script Writer
                                            </h6>
                                        </div>
                                    </div>
                                    <div className='feature-card mb-4 col-xxl-3 col-lg-4 col-sm-6 col-12'>
                                        <div className='card-box card-7'>
                                            <div className="icon"><img src='/assets/images/recruitment.png' alt='icons' /></div>
                                            <h6 className='mini-title'>Producer</h6>
                                        </div>
                                    </div>
                                    <div className='feature-card mb-4 col-xxl-3 col-lg-4 col-sm-6 col-12'>
                                        <div className='card-box card-8'>
                                            <div className="icon"><img src='/assets/images/library.png' alt='icons' /></div>
                                            <h6 className='mini-title'>Director</h6>
                                        </div>
                                    </div>
                                    <div className='feature-card mb-4 col-xxl-3 col-lg-4 col-sm-6 col-12'>
                                        <div className='card-box card-9'>
                                            <div className="icon"><img src='/assets/images/book.png' alt='icons' /></div>
                                            <h6 className='mini-title'>Event/Media Manager</h6>
                                        </div>
                                    </div>
                                    <div className='feature-card mb-4 col-xxl-3 col-lg-4 col-sm-6 col-12'>
                                        <div className='card-box card-10'>
                                            <div className="icon"><img src='/assets/images/recruitment.png' alt='icons' /></div>
                                            <h6 className='mini-title'>Public Relation Officer</h6>
                                        </div>
                                    </div>
                                    <div className='feature-card mb-4 col-xxl-3 col-lg-4 col-sm-6 col-12'>
                                        <div className='card-box card-11'>
                                            <div className="icon"><img src='/assets/images/library.png' alt='icons' /></div>
                                            <h6 className='mini-title'>Creative Advertisement
                                            </h6>
                                        </div>
                                    </div>
                                    <div className='feature-card mb-4 col-xxl-3 col-lg-4 col-sm-6 col-12'>
                                        <div className='card-box card-12'>
                                            <div className="icon"><img src='/assets/images/book.png' alt='icons' /></div>
                                            <h6 className='mini-title'>Graphic Artist
                                            </h6>
                                        </div>
                                    </div>
                                    <div className='feature-card mb-4 col-xxl-3 col-lg-4 col-sm-6 col-12'>
                                        <div className='card-box card-13'>
                                            <div className="icon"><img src='/assets/images/book.png' alt='icons' /></div>
                                            <h6 className='mini-title'>Creative Advertisement Designer
                                            </h6>
                                        </div>
                                    </div>
                                    <div className='feature-card mb-4 col-xxl-3 col-lg-4 col-sm-6 col-12'>
                                        <div className='card-box card-14'>
                                            <div className="icon"><img src='/assets/images/recruitment.png' alt='icons' /></div>
                                            <h6 className='mini-title'>Video Editor
                                            </h6>
                                        </div>
                                    </div>
                                    <div className='feature-card mb-4 col-xxl-3 col-lg-4 col-sm-6 col-12'>
                                        <div className='card-box card-15'>
                                            <div className="icon"><img src='/assets/images/library.png' alt='icons' /></div>
                                            <h6 className='mini-title'>Photo Editor</h6>
                                        </div>
                                    </div>
                                    <div className='feature-card mb-4 col-xxl-3 col-lg-4 col-sm-6 col-12'>
                                        <div className='card-box card-16'>
                                            <div className="icon"><img src='/assets/images/book.png' alt='icons' /></div>
                                            <h6 className='mini-title'>Researcher
                                            </h6>
                                        </div>
                                    </div>
                                </div>
                                {/* <div className='text-center'>
                                    <button className='button orange-button button-2' >Read More <i className="fa-solid fa-arrow-right"></i></button>
                                </div> */}
                            </div>                            
                        </div>
                    </div>
                </section>
            </FloatUpComponent>
            {/* director message */}
            <FloatUpComponent>
                <section className='parallax sections'>
                    <div className='container'>                    
                        <div className='row m-0 p-0 align-items-center'>
                            <div className='col-lg-6 order-2 order-lg-1'>
                                <div className='about-image-box'>
                                    <img src='/assets/images/director.png' className='about-image' alt="about"/>
                                </div>
                            </div>
                            <div className='col-lg-6 order-1 order-lg-2 mb-3 mb-lg-0 text-center'>
                                    <h3 className='heading drop-in'>Message From Director
                                        <br/><span className='line-text'><span className='line-cap'><i className="fa-solid fa-graduation-cap"></i></span></span>
                                    </h3>
                                {/* <h6 className='sub-heading drop-in'>Passion For Excellence.</h6>  */}
                                <p className='paragraph drop-in-2'>{homeData?.director_msg}</p>
                            </div>                            
                        </div>
                    </div>
                </section>
            </FloatUpComponent>
            {/* departments */}
            {/* <FloatUpComponent>
                <section className='sections bg-alternate'>
                    <div className='container'>                    
                        <div className='row m-0 p-0 align-items-center'>
                            <div className='col-xxl-6 mb-4 mb-xxl-0'>
                                    <h3 className='heading drop-in'>Academics Departments
                                        <br/><span className='line-text'><span className='line-cap'><i className="fa-solid fa-graduation-cap"></i></span></span>
                                    </h3>
                                <p className='paragraph drop-in-2'>
                                The generated Lorem Ipsum is therefore always free from repetition, injected humour, or non-characteristic words etc. It uses a dictionary of over 200 Latin words. The generated Lorem Ipsum is therefore always free from repetition, injected humour, or non-characteristic words etc.</p>
                                <p className='paragraph drop-in-2'>
                                There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words.
                                If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text.
                                </p>
                                <div className='text-center'>
                                    <button className="button orange-button button-2">View All <i className="fa-solid fa-arrow-right"></i></button>
                                </div>
                            </div>
                            <div className='col-xxl-6'>
                                <div className='row'>
                                    <div className='col-sm-6 mb-4'>
                                        <div className="department-card">
                                            <div className="img-container">
                                                <img src="/assets/images/text-books.png" className="card-img" alt=""/>
                                            </div>
                                            <div className="department-body">
                                                <h5 className="sub-heading">Literature</h5>
                                                <p className="paragraph">The generated Lorem Ipsum is therefore always free from repetition, injected humour, or non-characteristic words etc to generate Lorem Ipsum which looks reasonable.</p>
                                                <button className="button orange-button">Read More <i className="fa-solid fa-arrow-right"></i></button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-sm-6 mb-4'>
                                        <div className="department-card">
                                            <div className="img-container">
                                                <img src="/assets/images/physics.png" className="card-img" alt=""/>
                                            </div>
                                            <div className="department-body">
                                                <h5 className="sub-heading">Physics</h5>
                                                <p className="paragraph">The generated Lorem Ipsum is therefore always free from repetition, injected humour, or non-characteristic words etc to generate Lorem Ipsum which looks reasonable.</p>
                                                <button className="button orange-button">Read More <i className="fa-solid fa-arrow-right"></i></button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-sm-6 mb-4 mb-sm-0'>
                                        <div className="department-card">
                                            <div className="img-container">
                                                <img src="/assets/images/english.png" className="card-img" alt=""/>
                                            </div>
                                            <div className="department-body">
                                                <h5 className="sub-heading">English</h5>
                                                <p className="paragraph">The generated Lorem Ipsum is therefore always free from repetition, injected humour, or non-characteristic words etc to generate Lorem Ipsum which looks reasonable.</p>
                                                <button className="button orange-button">Read More <i className="fa-solid fa-arrow-right"></i></button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-sm-6'>
                                        <div className="department-card">
                                            <div className="img-container">
                                                <img src="/assets/images/math-book.png" className="card-img" alt=""/>
                                            </div>
                                            <div className="department-body">
                                                <h5 className="sub-heading">Mathematics</h5>
                                                <p className="paragraph">The generated Lorem Ipsum is therefore always free from repetition, injected humour, or non-characteristic words etc to generate Lorem Ipsum which looks reasonable.</p>
                                                <button className="button orange-button">Read More <i className="fa-solid fa-arrow-right"></i></button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </FloatUpComponent> */}
            {/* courses */}
            <FloatUpComponent >
                <section className='sections' id='courses'>
                    <div className='container'>                    
                        <div className='row m-0 p-0'>
                            <div className='col-12'>
                                {/* <div className='d-sm-flex d-block drop-in justify-content-between align-items-baseline'> */}
                                    <h3 className='heading'>Courses
                                        <br/><span className='line-text'><span className='line-cap'><i className="fa-solid fa-graduation-cap"></i></span></span>
                                    </h3>
                                    {/* <button className="button orange-button button-2 d-none d-sm-inline-block">View All <i className="fa-solid fa-arrow-right"></i></button> */}
                                {/* </div> */}
                                <div className='paragraph drop-in-2' dangerouslySetInnerHTML={{ __html: homeData?.homeData }}></div>
                                <div className='d-sm-none d-block text-center'>
                                    <button className="button orange-button button-2">View All <i className="fa-solid fa-arrow-right"></i></button>
                                </div>                                
                                <div className='services'>                                    
                                    <div className='services-columns'>
                                        <div className="service-inner">
                                            <div className='icon-box'>
                                                <img src={'/assets/images/healthcare.png'} alt='icon'/>
                                            </div>
                                            <div className="service-content">
                                                <h6 className='sub-heading'>Post Graduate Diploma in Journalism</h6>
                                                <p className="content paragraph">This book is a treatise on the theory of ethics, very popular during the Renaissance. The first line of Lorem Ipsum, “Lorem ipsum dolor sit amet. Join the course.</p>
                                                <Link to='/course-detail' className="button orange-button">Read More <i className="fa-solid fa-arrow-right"></i></Link>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='services-columns'>
                                        <div className="service-inner">
                                            <div className='icon-box'>
                                                <img src={'/assets/images/engineers.png'} alt='icon'/>
                                            </div>
                                            <div className="service-content">
                                                <h6 className='sub-heading'>Post Graduate Diploma in Graphics and Editing</h6>
                                                <p className="content paragraph">The standard chunk of Lorem Ipsum used since the 1500s is reproduced below for those interested by Cicero are also reproduced in their exact original form.</p>
                                                <Link to='/course-detail' className="button orange-button">Read More <i className="fa-solid fa-arrow-right"></i></Link>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='services-columns'>
                                        <div className="service-inner">
                                            <div className='icon-box'>
                                                <img src={'/assets/images/healthcare.png'} alt='icon'/>
                                            </div>
                                            <div className="service-content">
                                                <h6 className='sub-heading'>Diploma in Journalism</h6>
                                                <p className="content paragraph">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin ut odio tellus. Maecenas consectetur adipiscing elit Lorem ipsum dolor sit amet. Want to join here?</p>
                                                <Link to='/course-detail' className="button orange-button">Read More <i className="fa-solid fa-arrow-right"></i></Link>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='services-columns'>
                                        <div className="service-inner">
                                            <div className='icon-box'>
                                                <img src={'/assets/images/engineers.png'} alt='icon'/>
                                            </div>
                                            <div className="service-content">
                                                <h6 className='sub-heading'>Diploma in Graphics and Editing</h6>
                                                <p className="content paragraph">This book is a treatise on the theory of ethics, very popular during the Renaissance. The first line of Lorem Ipsum, “Lorem ipsum dolor sit amet. Join the course.</p>
                                                <Link to='/course-detail' className="button orange-button">Read More <i className="fa-solid fa-arrow-right"></i></Link>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='services-columns'>
                                        <div className="service-inner">
                                            <div className='icon-box'>
                                                <img src={'/assets/images/management.png'} alt='icon'/>
                                            </div>
                                            <div className="service-content">
                                                <h6 className='sub-heading'>Diploma in Media Research</h6>
                                                <p className="content paragraph">The standard chunk of Lorem Ipsum used since the 1500s is reproduced below for those interested by Cicero are also reproduced in their exact original form.</p>
                                                <Link to='/course-detail' className="button orange-button">Read More <i className="fa-solid fa-arrow-right"></i></Link>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='services-columns'>
                                        <div className="service-inner">
                                            <div className='icon-box'>
                                                <img src={'/assets/images/online-learning.png'} alt='icon'/>
                                            </div>
                                            <div className="service-content">
                                                <h6 className='sub-heading'>Certificate course in Editing, Anchoring, Cinematography, Social Media, Mobile Journalism etc.</h6>
                                                {/* <p className="content paragraph">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin ut odio tellus. Maecenas consectetur adipiscing elit Lorem ipsum dolor sit amet. Want to join here?</p> */}
                                                <Link to='/course-detail' className="button orange-button">Read More <i className="fa-solid fa-arrow-right"></i></Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>                                
                            </div>
                        </div>
                    </div>
                </section>
            </FloatUpComponent>
            {/* Faculty */}
            <FloatUpComponent >
                {faculty.length > 0 && 
                <section className='sections bg-alternate' id='faculty'>
                    <div className='container'>                    
                        <div className='row m-0 p-0'>
                            <div className='col-12'>
                                <h3 className='heading'>Our Faculty
                                    <br/><span className='line-text'><span className='line-cap'><i className="fa-solid fa-graduation-cap"></i></span></span>
                                </h3>                                                             
                            </div>
                            {faculty.map((result, index) =>
                                <div className="col-xl-3 col-sm-6" key={'faculty' + index}>
                                <div className="our-team">
                                    <img src={appConstant.CDN_URL+'/faculty_image/'+result?.image} alt=''/>
                                    <div className="team-content">
                                        <h3 className="sub-heading">{result?.title}</h3>
                                        <span className="paragraph">{result?.description}</span>
                                        {/* <ul className="social">
                                            <li><a href="/"><i className="fab fa-facebook-f"></i></a></li>
                                            <li><a href="/"><i className="fab fa-twitter"></i></a></li>
                                            <li><a href="/"><i className="fab fa-google-plus-g"></i></a></li>
                                        </ul> */}
                                    </div>
                                </div>
                            </div>
                            )}
                            
                        </div>
                    </div>
                </section>
                }
            </FloatUpComponent>
            {/* testimonials */}
            {/* <FloatUpComponent>
                <section className='sections'>
                    <div className='container'>                    
                        <div className='row m-0 p-0'>
                            <div className='col-12 mb-5'>
                                <h3 className='heading'>Testimonials
                                    <br/><span className='line-text'><span className='line-cap'><i className="fa-solid fa-graduation-cap"></i></span></span>
                                </h3>                                                             
                            </div>
                            <div className="col-xxl-3 col-sm-6 mb-5 mb-xxl-0">
                                <div className="testimonial">
                                    <span className="open quote">“</span>
                                    <div className="image">
                                        <div className="clip"></div>
                                        <img src="/assets/images/user-1.jpg" alt=""/>
                                    </div>
                                    <p className='paragraph'>This is some testimonial text for this month's CodePen challenge! This is some testimonial text for this month's CodePen challenge!</p>
                                    <div className="source">		
                                        <span className='paragraph'>Testimonial Source</span>
                                    </div>
                                    <span className="close quote">”</span>
                                </div>
                            </div>
                            <div className="col-xxl-3 col-sm-6 mb-5 mb-xxl-0">
                                <div className="testimonial">
                                    <span className="open quote">“</span>
                                    <div className="image">
                                        <div className="clip"></div>
                                        <img src="/assets/images/user-2.jpg" alt=""/>
                                    </div>
                                    <p className='paragraph'>This is some testimonial text for this month's CodePen challenge! This is some testimonial text for this month's CodePen challenge!</p>
                                    <div className="source">		
                                        <span className='paragraph'>Testimonial Source</span>
                                    </div>
                                    <span className="close quote">”</span>
                                </div>
                            </div>
                            <div className="col-xxl-3 col-sm-6 mb-5 mb-sm-0">
                                <div className="testimonial">
                                    <span className="open quote">“</span>
                                    <div className="image">
                                        <div className="clip"></div>
                                        <img src="/assets/images/user-3.jpg" alt=""/>
                                    </div>
                                    <p className='paragraph'>This is some testimonial text for this month's CodePen challenge! This is some testimonial text for this month's CodePen challenge!</p>
                                    <div className="source">		
                                        <span className='paragraph'>Testimonial Source</span>
                                    </div>
                                    <span className="close quote">”</span>
                                </div>
                            </div>
                            <div className="col-xxl-3 col-sm-6">
                                <div className="testimonial">
                                    <span className="open quote">“</span>
                                    <div className="image">
                                        <div className="clip"></div>
                                        <img src="/assets/images/user-2.jpg" alt=""/>
                                    </div>
                                    <p className='paragraph'>This is some testimonial text for this month's CodePen challenge! This is some testimonial text for this month's CodePen challenge!</p>
                                    <div className="source">		
                                        <span className='paragraph'>Testimonial Source</span>
                                    </div>
                                    <span className="close quote">”</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </FloatUpComponent> */}
            {/* contact us */}
            <FloatUpComponent>
                <section className='contact sections'>
                <div className='container'>
                    <div className='row m-0 p-0 position-relative'>
                        <div className='col-lg-1'></div>
                        <div className='col-lg-11 p-lg-5 p-3 contact-box'>
                            <div className='row m-0 p-0'>
                                <div className='col-xl-4 col-lg-5 p-0 mb-4 mb-lg-0'>
                                    <div className='contact-info p-lg-4 p-3'>
                                        <h5 className='heading mb-3'><big>Contact Us</big></h5>
                                        <div className='d-flex mb-sm-4 mb-3'>
                                            <div className='contact-icons'><i className="fa-solid fa-location-dot"></i></div>
                                            <div className='contact-content flex-grow-1'>{`${appConstant.ADDRESS_URL}`}</div>
                                        </div>
                                        <div className='d-flex mb-sm-4 mb-3'>
                                            <div className='contact-icons'><i className="fa-solid fa-envelope"></i></div>
                                            <a href={`mailto:${appConstant.EMAIL_URL}`} rel="noreferrer"  className='contact-content flex-grow-1'>{`${appConstant.EMAIL_URL}`}</a>
                                        </div>
                                        <div className='d-flex mb-sm-4 mb-3'>
                                            <div className='contact-icons'><i className="fa-solid fa-phone"></i></div>
                                            <a href={`tel:${appConstant.PHONE_URL}`} rel="noreferrer"  className='contact-content flex-grow-1'>{`${appConstant.PHONE_URL}`}</a>
                                        </div>
                                        <div className='d-flex justify-content-center w-100'>
                                            <a className="contact-social-icon" href={`${appConstant.FACEBOOK_URL}`} target="_blank" rel="noreferrer"><i className="fa-brands fa-facebook"></i></a>
                                            <a className="contact-social-icon" href={`${appConstant.TWITTER_URL}`} target="_blank" rel="noreferrer"><i className="fa-brands fa-twitter"></i></a>
                                            <a className="contact-social-icon" href={`${appConstant.LINKEDIN_URL}`} target="_blank" rel="noreferrer"><i className="fa-brands fa-linkedin"></i></a>
                                            <a className="contact-social-icon" href={`${appConstant.INSTAGRAM_URL}`} target="_blank" rel="noreferrer"><i className="fa-brands fa-instagram"></i></a>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-xl-8 col-lg-7 p-lg-4 p-3 ps-lg-0'>
                                    <h6 className='heading mb-0'><big>Get in Touch</big></h6>
                                    <p className='paragraph text-center mb-4'>Feel free to drop us a line below</p>
                                    <form>
                                        <div className='input-box mb-3'>
                                            <label className='contact-label'>Name</label>
                                            <input className='contact-input' name='name' placeholder='Enter your full name' />
                                        </div>
                                        <div className='input-box mb-3'>
                                            <label className='contact-label'>Email</label>
                                            <input className='contact-input' name='email' placeholder='Enter your email id'/>
                                        </div>
                                        <div className='input-box mb-3'>
                                            <label className='contact-label'>Phone</label>
                                            <input className='contact-input' name='number' placeholder='Enter your phone number'/>
                                        </div>
                                        <div className='input-box mb-4'>
                                            <label className='contact-label'>Message</label>
                                            <textarea className='contact-input' rows='3' name='message' placeholder='Enter your message' ></textarea>
                                        </div>
                                        <button className='button orange-button button-2' type='submit' >Submit</button>
                                    </form>                                     
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                </section>
            </FloatUpComponent>
            {/* footer */}
            <Footer />            
        </>
    );
}
export default Home;