import React from 'react';
import ScrollToTop from './ScrollToTop';
import appConstant from './constant/constant.json';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';

function Header( props) {
    return (
        <>
        <ScrollToTop />
        <header className='header'>
            <div className='top-header'>
                <div className='container'>
                    <div className='row align-items-center'>
                        <div className='col-md-8 d-md-block d-none'> 
                            <div className='d-flex flex-wrap'> 
                                <div className='d-none d-md-block'>                      
                                    <i className="fa-solid fa-phone"></i> <span>&nbsp;</span>
                                    <a className="links"  href={`tel:${appConstant.PHONE_URL}`} rel="noreferrer">{`${appConstant.PHONE_URL}`}</a>
                                    <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                    <i className="fa-solid fa-envelope"></i><span>&nbsp;</span>
                                    <a className="links" href={`mailto:${appConstant.EMAIL_URL}`} rel="noreferrer">{`${appConstant.EMAIL_URL}`}</a>
                                    <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                </div>
                                <div className='d-block d-xl-inline d-lg-none'>
                                    <div className='d-flex'>
                                        <strong>Notice :</strong>
                                        <span>&nbsp;&nbsp;&nbsp;</span>
                                        <div id="notice" className="carousel slide" data-bs-ride="carousel">
                                            <div className="carousel-inner">
                                                <div className="carousel-item active">
                                                    <a href='/' target='_blank'>Educational Tour</a>
                                                </div>
                                                <div className="carousel-item">
                                                    <a href='/' target='_blank'>Welcome Program</a>
                                                </div>
                                                <div className="carousel-item">
                                                    <a href='/' target='_blank'>Convocation Program</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-4 col-12 text-md-end text-center'>
                            <a className="links" href={`${appConstant.FACEBOOK_URL}`} target="_blank" rel="noreferrer"><i className="fa-brands fa-facebook"></i></a>
                            <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                            <a className="links" href={`${appConstant.TWITTER_URL}`} target="_blank" rel="noreferrer"><i className="fa-brands fa-twitter"></i></a>
                            <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                            <a className="links" href={`${appConstant.LINKEDIN_URL}`} target="_blank" rel="noreferrer"><i className="fa-brands fa-linkedin"></i></a>
                            <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                            <a className="links" href={`${appConstant.INSTAGRAM_URL}`} target="_blank" rel="noreferrer"><i className="fa-brands fa-instagram"></i></a>
                            <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                            <button className='button orange-button'>Online Course</button>
                        </div>
                    </div>
                </div>
            </div>
        </header>
        <nav className="navbar navbar-expand-lg bg-white navbar-light sticky-top">
            <div className="container">
                <a className="navbar-brand" href="/">
                    <img className='logo' alt='logo' src='/assets/images/logo.png' />
                </a>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#collapsibleNavbar">
                <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="collapsibleNavbar">
                    <ul className="navbar-nav ms-auto">
                        <li className="nav-item">
                            <Link className={'nav-link ' + (props.id === "1" ? 'active' : '')} to="/">Home</Link>
                        </li>
                        <li className="nav-item dropdown">
                            <Link className={'nav-link dropdown-toggle ' + (props.id === "2" ? 'active' : '')} data-bs-toggle="dropdown" to="/about">About Us</Link>
                            <ul className="dropdown-menu">
                                <li><Link className='dropdown-item' to="/about">About</Link></li>
                                <li><Link className='dropdown-item' to="/why-iims">Why IIMS</Link></li>
                            </ul>
                        </li>
                        <li className="nav-item">
                            <HashLink className={'nav-link ' + (props.id === "3" ? 'active' : '')} smooth  to="/#courses">Courses</HashLink>
                        </li>
                        <li className="nav-item">
                            <HashLink className={'nav-link ' + (props.id === "4" ? 'active' : '')} smooth  to="/#career">Career</HashLink>
                        </li>
                        <li className="nav-item">
                            <HashLink className={'nav-link ' + (props.id === "5" ? 'active' : '')} smooth  to="/#faculty">Faculty</HashLink>
                        </li>
                        <li className="nav-item">
                            <a className={'nav-link ' + (props.id === "6" ? 'active' : '')} href="/contact">Contact Us</a>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
        </>
    );
}
export default Header;