import React from 'react';
function Banner() {
    return (
        <div className='banner-box'> 
            <img src={'/assets/images/banner-img.jpg'} alt='' className='banner-img' />
            <div className='banner-overlay'>
                <div className='container'>
                    <div className='d-flex'>
                        <h3 className="heading">Why IIMS</h3>
                    </div>                
                </div>                
            </div>            
        </div>
    );
}
export default Banner;