import React from 'react';
import Header from './shared/Header';
import Footer from './shared/Footer';
import appConstant from './shared/constant/constant.json';

function Contact() {
    return (
        <>
            <Header id="6" />                
                <iframe title='IIMS' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3505.1116564142003!2d77.21409358725502!3d28.53636342584393!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390ce2218da19f61%3A0xf1ac486b656f5480!2sApeejay%20School%20-%20Panchsheel%20Park!5e0!3m2!1sen!2sin!4v1723633878064!5m2!1sen!2sin" className='contact-map' allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                <section className='container'>
                    <div className='row m-0 p-0 my-5'>
                        <div className='col-lg-4 mb-4 mb-lg-0'>
                            <div className='contact-icon-cards'>
                                <div className='contactPage-icons'><i className="fa-solid fa-phone"></i></div>
                                <a href={`tel:${appConstant.PHONE_URL}`} rel="noreferrer" className='contactPage-content flex-grow-1'>{`${appConstant.PHONE_URL}`}</a>
                            </div>
                        </div>
                        <div className='col-lg-4 mb-4 mb-lg-0'>
                            <div className='contact-icon-cards'>
                                <div className='contactPage-icons'><i className="fa-solid fa-envelope"></i></div>
                                <a href={`mailto:${appConstant.EMAIL_URL}`} rel="noreferrer" className='contactPage-content flex-grow-1'>{`${appConstant.EMAIL_URL}`}</a>
                            </div>
                        </div>
                        <div className='col-lg-4'>
                            <div className='contact-icon-cards'>
                                <div className='contactPage-icons'><i className="fa-solid fa-location-dot"></i></div>
                                <div className='contactPage-content flex-grow-1'>{`${appConstant.ADDRESS_URL}`}</div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className='sections contact'>
                    <div className='container'>
                        <div className='row m-0 p-0 align-items-center'>
                            <div className='col-lg-6 mb-4 mb-lg-0 p-0'>
                                <form className='contactPage-form'>
                                    <h3 className='heading text-white mb-2'>Get in Touch
                                        <br/><span className='line-text'><span className='line-cap'><i className="fa-solid fa-graduation-cap"></i></span></span>
                                    </h3>
                                    <p className='paragraph text-white text-center mb-4'>Feel free to drop us a line below</p>
                                    <div className='input-box mb-3'>
                                        <label className='contact-label text-white'>Name</label>
                                        <input className='contact-input' name='name' placeholder='Enter your full name' />
                                    </div>
                                    <div className='input-box mb-3'>
                                        <label className='contact-label text-white'>Email</label>
                                        <input className='contact-input' name='email' placeholder='Enter your email id'/>
                                    </div>
                                    <div className='input-box mb-3'>
                                        <label className='contact-label text-white'>Phone</label>
                                        <input className='contact-input' name='number' placeholder='Enter your phone number'/>
                                    </div>
                                    <div className='input-box mb-4'>
                                        <label className='contact-label text-white'>Message</label>
                                        <textarea className='contact-input' rows='3' name='message' placeholder='Enter your message' ></textarea>
                                    </div>
                                    <div className='text-center'>
                                        <button className='button orange-button' type='submit' >Submit</button>
                                    </div>                                    
                                </form>
                            </div>
                            <div className='col-lg-6 p-0'>
                                <img src="/assets/images/contact.png" className="aboutPage-image" alt="" />
                            </div>
                        </div>
                    </div>  
                </section>
            <Footer /> 
        </>
    );
}
export default Contact;